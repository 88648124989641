.App {
  text-align: center;
}

#proximamente {
  
  overflow: hidden; /* Para ocultar las letras mientras aparecen */
  border-right: .15em solid rgb(255, 255, 255); /* Línea vertical simulando el cursor */
  white-space: nowrap; /* Para que el texto no se divida en varias líneas */
  margin: 0 auto; /* Centra el texto horizontalmente */
  letter-spacing: .15em; /* Espacio adicional entre letras */
  animation: typing 3s steps(14) infinite;
  color: #ffff;
  margin-top: 3rem;
}

/* Animación para escribir letra por letra */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 90%;
  }
}
@media(min-width:768px){
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 28%;
  }
}
}

#proximamente p{
  margin-top: 3px;
  margin-bottom: 5px;
}

.proximamente {
  margin-top: 20px;
  font-size: 2rem;
  font-family: "Courier New";
    animation: typing 5s steps(38) 1s 1 normal both, blink 1s steps(1) infinite;
    width: 0;
  overflow: hidden;
  white-space: nowrap;
}




/* Animación con keyframe llamada "latidos" */
@keyframes latidos {
  from { transform: none; }
  30% { transform: scale(1.05); }
}
/* En la clase corazon vamos a llamar latidos en la animación  */
.corazon {
display: inline-block;
font-size: 150px;
text-shadow: 0 0 10px #222,1px 1px  0 #450505;
color: red;
animation: latidos 3s infinite;
transform-origin: center;
}

.App-logo {
  height: 10rem;
  pointer-events: none;
  border-radius: 100%;
}

.App-header {
  background-color: #0C0C0C;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
