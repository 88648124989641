body .principal{
background-image: url(../../../public/IKS_Recursos_Web_Fondo_Mobile.jpg);
background-repeat: no-repeat;
background-size: cover ;
background-position: top;
height: 100vh;
background-attachment: fixed;
}

#root{
    height: 100%;
}

.isologo{
    margin-top: 3rem;    
    width: 10rem;
}

.texto-1{
    width: 12rem;
}
.texto-2{
    width: 14rem;
    animation: latidos 10s infinite;
}
.texto-3{
    width: 12rem;
}
.texto-4{
    width: 11rem;
}
.texto-5{
    width: 6rem;
}



.logotipo{
}

.logos{
    width: 40px;
    height: 40px;
}

.logos-texto{
    height: 40px;
}
.divisor{
    border: 1.5px solid #fff;
    width: 15%;
}

@media(min-width: 768px){
    body .principal{
        background-image: url(../../../public/IKS_Recursos_Web_Fondo.webp);       
        }

    .isologo{
        margin-top: 15vh;    
        width: 35vh;
    }
    .texto-1{
        width: 25%;
    }
    .texto-2{
        width: 33%;
    }
    .texto-3{
        margin-top: 5rem;
        margin-bottom: 4rem;
        width: 12%;
    }
    .texto-4{
        width: 100%;
    }
    .texto-5{
        width: 100%;
    }


    .logos{
        width: 70px;
        height: 70px;
    }
    
    .logos-texto{
        height: 70px;
    }

    .divisor{
        border: 1.5px solid #fff;
        width: 5%;
    }
}